.linkItem {
  /* width: min-content;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--bg-secondary-300); */
  text-decoration: none;
  /* transform: translateX(-1rem);
  filter: invert(100%); */
}

/* .linkItem:hover {
  text-decoration: underline;
  text-decoration-thickness: auto;
  text-decoration-color: var(--bg-primary-300);
  text-underline-offset: 3px;
} */

.listItem {
  width: min-content;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  transform: translateX(-1rem);
}

.listItem:hover {
  text-decoration: underline;
  text-decoration-thickness: auto;
  /* text-decoration-color: var(--bg-primary-300); */
  text-underline-offset: 3px;
}
