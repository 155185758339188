.bioLink {
  text-decoration: none;
}

.bioLink:hover {
  text-decoration: underline;
}

.bioTimeline {
  padding: 0 !important;
}
