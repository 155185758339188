.mainDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);

  font-size: 1rem;
  text-align: center;
  border-radius: 0.5em;
  max-width: 25em;
  height: max-content;
  padding: 2em;

  background-color: var(--bg-neutral-750);
}

.mainDiv h2 {
  width: 90%;
  margin: 0 auto;
  background-color: var(--bg-primary-300);
}

.mainDiv p {
  font-weight: 500;
  /* margin-top: 0; */
  margin-bottom: 2rem;
}

.mainDiv p span {
  padding: 0.08em 0.4em;
  color: #f9f9f9;

  background-color: #929292;
  border-radius: 1em;
}

.mainDiv a {
  font-weight: 500;
  text-decoration: none;
  color: var(--bg-primary-700);
}

.mainDiv a:hover {
  color: var(--bg-primary-800);
}

@media (max-width: 600px) {
  .mainDiv {
    width: 80vw;
    max-width: 25em;
  }

  .mainDiv h2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--bg-primary-300);
  }

  .mainDiv p {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    word-wrap: break-word;
  }

  .maindiv p {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    word-wrap: break-word;
  }
}
