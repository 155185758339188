:root {
  --bg-primary-300: hsl(9, 50%, 48%);
  --bg-primary-500: #ed765e;
  --bg-primary-700: hsl(10, 79%, 45%);
  --bg-primary-800: hsl(10, 79%, 35%);

  --bg-secondary-300: hsl(187, 71%, 41%);
  --bg-secondary-300: hsl(190, 80%, 38%);

  --bg-neutral-850: #1e1e1e;
  --bg-neutral-800: #232323;
  --bg-neutral-750: #252525;
  --bg-neutral-700: hsl(30, 6%, 43%);
  --bg-neutral-500: hsl(28, 98%, 62%);

  --text-neutral-700: #fafafa;
  --text-neutral-300: #e7e7e7;
  --text-neutral-500: #f0f0f0;

  --text-primary: #fff;
  --text-secondary: rgba(255, 255, 255, 0.7);
  --text-disabled: rgba(255, 255, 255, 0.5);
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  /* display: flex;
  flex-direction: column; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Scrolbar */
::-webkit-scrollbar {
  width: 1rem;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #e7e7e7;
  border: 4px solid #1e1e1e;
  border-radius: 10px;
  /* width: 8px; */
  height: 200px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
